import React from "react";
import styled from "styled-components";
import VerticalBar from "./VerticalBar";
import Dot from "./Dot";

const SpecificatiiWrap = styled.div`
  // border: 2px solid red;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 15px;
  width: 70%;
`;

const SpecificatiiTitle = styled.div`
  font-weight: 600;
  font-size: 20px;
  display: flex;
  align-items: center;
  color: #003366;
`;
const DomeniiUtilizare = styled.div`
  width: 100%;
`;

const SpecificatiiSubtitle = styled.div`
  display: flex;
  align-items: center;
  font-weight: 500;
  margin-bottom: 10px;
  margin-top: 30px;
  font-size: 17px;
`;
const specificatiiStyle = {
  plainText: {
    // fontSize: "13px",
  },
};
const Specificatii = ({ refSpecificatii }) => {
  return (
    <SpecificatiiWrap ref={refSpecificatii}>
      <SpecificatiiTitle>
        <Dot diameter={10} color={"rgba(28, 82, 68, 255)"} />
        <div style={{ marginLeft: "10px", marginRight: "10px" }}>
          Specificații
        </div>
        <Dot diameter={10} color={"rgba(28, 82, 68, 255)"} />
      </SpecificatiiTitle>
      <DomeniiUtilizare>
        <div>
          <SpecificatiiSubtitle>
            <VerticalBar height={20} width={3} />
            <div style={{ marginLeft: "10px", color: "#003366" }}>
              Domenii de utilizare
            </div>
          </SpecificatiiSubtitle>
          <div style={specificatiiStyle.plainText}>
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the industry's standard dummy text
            ever since the 1500s, when an unknown printer took a galley of type
            and scrambled it to make a type specimen book. It has survived not
            only five centuries, but also the leap into electronic typesetting,
            remaining essentially unchanged. It was popularised in the 1960s
            with the release of Letraset sheets containing Lorem Ipsum passages,
            and more recently with desktop publishing software like Aldus
            PageMaker including versions of Lorem Ipsum. Why do we use it? It is
            a long established fact that
          </div>
        </div>
        <div>
          <SpecificatiiSubtitle>
            <VerticalBar height={20} width={3} />
            <div style={{ marginLeft: "10px", color: "#003366" }}>
              Avantajele utilizării izo-35
            </div>
          </SpecificatiiSubtitle>
          <div>
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the industry's standard dummy text
            ever since the 1500s, when an unknown printer took a galley of type
            and scrambled it to make a type specimen book. It has survived not
            only five centuries, but also the leap into electronic typesetting,
            remaining essentially unchanged. It was popularised in the 1960s
            with the release of Letraset sheets containing Lorem Ipsum passages,
            and more recently with desktop publishing software like Aldus
            PageMaker including versions of Lorem Ipsum. Why do we use it? It is
            a long established fact that
          </div>
        </div>
        <div>
          <SpecificatiiSubtitle>
            <VerticalBar height={20} width={3} />
            <div style={{ marginLeft: "10px", color: "#003366" }}>
              Specificații tehnice
            </div>
          </SpecificatiiSubtitle>
          <table className="table table-striped ">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">Rezistenta</th>
                <th scope="col">Alungire</th>
                <th scope="col">Grosime</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th scope="row">1</th>
                <td>maxima</td>
                <td>cat mai lunga</td>
                <td>@Valeeu</td>
              </tr>
              <tr>
                <th scope="row">1</th>
                <td>maxima</td>
                <td>cat mai lunga</td>
                <td>@Valeeu</td>
              </tr>
              <tr>
                <th scope="row">1</th>
                <td>maxima</td>
                <td>cat mai lunga</td>
                <td>@Valeeu</td>
              </tr>
            </tbody>
          </table>
        </div>
      </DomeniiUtilizare>
    </SpecificatiiWrap>
  );
};

export default Specificatii;
