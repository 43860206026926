import React from 'react'

const Dot = ({ diameter, color }) => {
  return (
    <div
      style={{
        height: `${diameter}px`,
        width: `${diameter}px`,
        backgroundColor: `${color}`,
        borderRadius: '50%',
        display: 'inline-block',
      }}
    ></div>
  )
}

export default Dot
