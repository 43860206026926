import React, { useState } from "react";
import styled from "styled-components";
import VerticalBar from "./VerticalBar";
import { Container, Row, Col } from "react-bootstrap";
import { GalleryImg } from "../Data/GalleryImg";
import Modal from "./Modal";
import Dot from "./Dot";

const ExempleWrap = styled.div`
  //border: 2px solid blue;
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: rgba(28, 82, 68, 0.5);

  width: 100%;
`;
const ExempleTitle = styled.div`
  margin-top: 30px;
  font-weight: 600;
  font-size: 25px;
  display: flex;
  align-items: center;
  color: white;
`;
const Subtitle = styled.div`
  font-size: 18px;
  font-weight: 700;
  text-align: center;
`;
const ContentRow = styled(Row)`
  margin-top: 80px;
  justify-content: center;
  margin-bottom: 80px;
`;

const Gallery = styled.div`
  //   border: 2px solid red;
`;
const ContentCol = styled(Col)`
  padding: 20px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  width: 45%;
  margin-right: 5px;
  margin-left: 5px;
  background-color: rgba(254, 231, 197, 0.8);
`;
const ColGallery = styled(Col)`
  display: flex;
  justify-content: center;
  align-items: center;
`;
const GalleryImage = styled.div`
  padding: 10px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  margin-top: 3px;
  margin-bottom: 3px;
`;
const galleryImgCuIzo = GalleryImg.filter((item) => {
  return item.categorie === "cu izo";
});

const galleryImgFaraIzo = GalleryImg.filter((item) => {
  return item.categorie === "fara izo";
});
const ExemplePoduri = ({ refExemplelucrari }) => {
  const [openModal, setOpenModal] = useState(false);
  const [imageId, setImageId] = useState(0);
  const [currentSrc, setCurrentSrc] = useState("");

  const handleImageClick = (id) => {
    setOpenModal(!openModal);
    setImageId(id);
    setCurrentSrc(GalleryImg[id - 1].src);
  };

  return (
    <>
      <ExempleWrap ref={refExemplelucrari}>
        <ExempleTitle>
          <Dot diameter={10} color={"#ff9966"} />
          <div style={{ marginLeft: "10px", marginRight: "10px" }}>
            Exemple de poduri cu și fără izo-35
          </div>
          <Dot diameter={10} color={"#ff9966"} />
        </ExempleTitle>

        <ContentRow>
          <ContentCol xs={6}>
            <Subtitle> Cu Izo-35</Subtitle>
            <Gallery>
              <Row>
                {galleryImgCuIzo.map((item) => {
                  return (
                    <ColGallery key={item.id} xs={12} sm={6} lg={4}>
                      <GalleryImage>
                        <img
                          src={item.src}
                          style={{
                            width: "100px",
                            height: "100px",
                          }}
                          alt="gallery"
                          onClick={() => handleImageClick(item.id)}
                        />
                      </GalleryImage>
                    </ColGallery>
                  );
                })}
              </Row>
            </Gallery>
          </ContentCol>
          <ContentCol xs={6}>
            <Subtitle> Fara Izo-35</Subtitle>
            <Gallery>
              <Row>
                {galleryImgFaraIzo.map((item) => {
                  return (
                    <ColGallery key={item.id} xs={12} sm={6} lg={4}>
                      <GalleryImage>
                        <img
                          src={item.src}
                          style={{
                            width: "100px",
                            height: "100px",
                          }}
                          alt="gallery"
                          onClick={() => handleImageClick(item.id)}
                        />
                      </GalleryImage>
                    </ColGallery>
                  );
                })}
              </Row>
            </Gallery>
          </ContentCol>
        </ContentRow>

        {openModal && (
          <Modal
            setOpenModal={setOpenModal}
            openModal={openModal}
            currentSrc={currentSrc}
            setCurrentSrc={setCurrentSrc}
            imageId={imageId}
          />
        )}
      </ExempleWrap>
    </>
  );
};
export default ExemplePoduri;
