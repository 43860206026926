import React from "react";
import styled from "styled-components";
import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import { FaBars } from "react-icons/fa";
import SideNav from "./SideNav";

const Navbar = styled.div`
  // height: 80px;
  // padding: 20px;
  // display: flex;
  // align-items: center;
  // justify-content: end;
  // position: fixed;
  // top: 0px;
  // right: 0;
  // left: 0;
  // z-index: 100;
  // border-bottom: 2px solid rgba(0, 0, 0, 0.4);
  // background-color: rgba(0, 0, 0, 0.1);
`;
const LogoSection = styled.div`
  //position: absolute;
  // left: 30px;
  // display: flex;
  // align-items: center;
`;
const LogoImage = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 200px;
`;
const Title = styled.div`
  font-weight: bold;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
const Menu = styled.div`
  //border: 1px solid #f5fa60;
  font-size: 17px;
  padding: 10px;
  display: flex;
  justify-content: center;
  position: sticky;
  top: 0;
  //background-color: rgba(8, 56, 99, 255);
  background-color: rgba(28, 82, 68, 255);

  @media screen and (max-width: 1000px) {
    display: none;
  }
`;
const HambourgerIcon = styled.div`
  color: rgba(0, 0, 0, 0.8);
  position: absolute;
  right: 30px;
  font-size: 30px;
  display: flex;
  justify-content: center;
  align-items: center;

  @media screen and (min-width: 1000px) {
    display: none;
  }
`;
const NavLink = styled(Link)`
  padding: 5px 30px 5px 30px;
  cursor: pointer;
  color: white;
  text-decoration: none;
  font-weight: 500;

  &:hover {
    color: #f7b876;
  }
`;

const Header = ({
  sideNav,
  showSideNav,
  handleClickScroll,
  refHome,
  refSpecificatii,
  refExemplelucrari,
  refPortofoliu,
  refContact,
}) => {
  return (
    <>
      {/* ---------------------------------Meniul pe larg--------------------------------- */}
      <Menu>
        <NavLink onClick={() => handleClickScroll(refHome)}>Home</NavLink>
        <NavLink onClick={() => handleClickScroll(refSpecificatii)}>
          Specificații
        </NavLink>
        <NavLink onClick={() => handleClickScroll(refExemplelucrari)}>
          Exemple lucrări
        </NavLink>
        <NavLink onClick={() => handleClickScroll(refPortofoliu)}>
          Portofoliu
        </NavLink>
        <NavLink onClick={() => handleClickScroll(refContact)}>Contact</NavLink>
      </Menu>
      {/* ---------------------------------LOGO--------------------------------- */}
      <LogoSection>
        <LogoImage>
          <img
            src="/images/header/logo-image-patrat-portocaliu.png"
            style={{ maxHeight: "200px", marginLeft: "5px" }}
          />
          <Title>
            <div style={{ fontSize: "30px", textAlign: "center" }}>
              HIDROIZOLAȚII DE CEA MAI BUNĂ CALITATE
            </div>
            <div style={{ color: "#f7b876" }}>Telefon: 0748171704</div>
            <div style={{ color: "#f7b876" }}>Email : suciuma1@gmail.com</div>
          </Title>
          <img
            src="/images/header/logo.jpg"
            style={{ maxHeight: "150px", marginLeft: "5px" }}
          />
        </LogoImage>
      </LogoSection>
      <img
        src="/images/header/header-img.jpg"
        style={{ width: "100%", minHeight: "300px" }}
      />

      {/* ---------------------------------Meniul pe telefon ca Hambourger Icon--------------------------------- */}

      {/* ---------------------------------Meniul lateral pe telefon--------------------------------- */}
      {sideNav && (
        <SideNav
          showSideNav={showSideNav}
          handleClickScroll={handleClickScroll}
          refHome={refHome}
          refSpecificatii={refSpecificatii}
          refExemplelucrari={refExemplelucrari}
          refPortofoliu={refPortofoliu}
          refContact={refContact}
        />
      )}
      {!sideNav && (
        <HambourgerIcon onClick={showSideNav}>
          <FaBars />
        </HambourgerIcon>
      )}
    </>
  );
};

export default Header;
