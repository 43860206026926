import React from 'react'

const VerticalBar = ({ height, width }) => {
  return (
    <>
      <div
        style={{
          height: `${height}px`,
          width: `${width}px`,
          backgroundColor: '#ff9966',
        }}
      ></div>
    </>
  )
}

export default VerticalBar
