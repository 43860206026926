import React from "react";
import { NavLink as Link } from "react-router-dom";
import styled from "styled-components";
import * as AiIcons from "react-icons/ai";

const FullPageWrap = styled.div`
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 101;
`;

const SideWrap = styled.div`
  background-color: rgba(0, 0, 0, 0.8);
  width: 250px;
  height: 100vh;
  position: absolute;
  right: 0;
  top: 0;
  display: flex;
  flex-direction: column;
`;
const NavLink = styled(Link)`
  text-decoration: none;
  padding: 10px;
  cursor: pointer;
  color: rgba(0, 191, 254, 255);
  margin-left: 10px;
  font-size: 18px;
`;

const NavIconClose = styled.div`
  font-size: 2em;
  color: rgba(0, 191, 254, 255);
  margin-top: 10px;
  margin-left: auto;
  margin-right: 10px;
`;

const SideNav = ({
  sideNav,
  showSideNav,
  handleClickScroll,
  refHome,
  refSpecificatii,
  refExemplelucrari,
  refPortofoliu,
  refContact,
}) => {
  return (
    <FullPageWrap sideNav={sideNav}>
      <SideWrap>
        {/* -------------X-ul de inchidere a side nav---------------- */}
        <NavIconClose
          onClick={() => {
            showSideNav();
          }}
        >
          <AiIcons.AiOutlineClose />
        </NavIconClose>
        {/* -------------Meniul din laterala ---------------- */}
        <NavLink
          onClick={() => {
            showSideNav();
            handleClickScroll(refHome);
          }}
        >
          Home
        </NavLink>
        <NavLink
          onClick={() => {
            showSideNav();
            handleClickScroll(refSpecificatii);
          }}
        >
          Specificații
        </NavLink>
        <NavLink
          onClick={() => {
            showSideNav();
            handleClickScroll(refExemplelucrari);
          }}
        >
          Exemple lucrări
        </NavLink>
        <NavLink
          onClick={() => {
            showSideNav();
            handleClickScroll(refPortofoliu);
          }}
        >
          Portofoliu
        </NavLink>
        <NavLink
          onClick={() => {
            showSideNav();
            handleClickScroll(refContact);
          }}
        >
          Contact
        </NavLink>
      </SideWrap>
    </FullPageWrap>
  );
};

export default SideNav;
