import React from "react";

const Portofoliu = ({ refPortofoliu }) => {
  return (
    <div
      style={{ border: "2px solid green", width: "100%" }}
      ref={refPortofoliu}
    >
      PORTOFOLIU
    </div>
  );
};

export default Portofoliu;
