export const GalleryImg = [
  {
    id: 1,
    categorie: 'cu izo',
    src: 'images/exemple_poduri/poduri_cu_izo35/1.jpeg',
  },
  {
    id: 2,
    categorie: 'cu izo',
    src: 'images/exemple_poduri/poduri_cu_izo35/2.jpeg',
  },
  {
    id: 3,
    categorie: 'cu izo',
    src: 'images/exemple_poduri/poduri_cu_izo35/3.jpeg',
  },
  {
    id: 4,
    categorie: 'cu izo',
    src: 'images/exemple_poduri/poduri_cu_izo35/4.jpeg',
  },
  {
    id: 5,
    categorie: 'cu izo',
    src: 'images/exemple_poduri/poduri_cu_izo35/5.jpeg',
  },
  {
    id: 6,
    categorie: 'cu izo',
    src: 'images/exemple_poduri/poduri_cu_izo35/6.jpeg',
  },
  {
    id: 7,
    categorie: 'fara izo',
    src: 'images/exemple_poduri/poduri_fara_izo35/1.jpeg',
  },
  {
    id: 8,
    categorie: 'fara izo',
    src: 'images/exemple_poduri/poduri_fara_izo35/2.jpeg',
  },
  {
    id: 9,
    categorie: 'fara izo',
    src: 'images/exemple_poduri/poduri_fara_izo35/3.jpeg',
  },
  {
    id: 10,
    categorie: 'fara izo',
    src: 'images/exemple_poduri/poduri_fara_izo35/4.jpeg',
  },
  {
    id: 11,
    categorie: 'fara izo',
    src: 'images/exemple_poduri/poduri_fara_izo35/5.jpeg',
  },
  {
    id: 12,
    categorie: 'fara izo',
    src: 'images/exemple_poduri/poduri_fara_izo35/6.jpeg',
  },
]
