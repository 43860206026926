import Radium, { StyleRoot } from "radium";
import React, { useState } from "react";
import { useRef } from "react";
import Header from "./Components/Header";
import styled from "styled-components";
import Specificatii from "./Components/Specificatii";
import ExemplePoduri from "./Components/ExemplePoduri";
import Portofoliu from "./Components/Portofoliu";
import Footer from "./Components/Footer.js";

const Content = styled.div`
  //border: 2px solid red;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 50px;
`;

function App() {
  const [sideNav, setSideNav] = useState(false);
  const showSideNav = () => {
    setSideNav(!sideNav);
  };

  const refHome = useRef(null);
  const refSpecificatii = useRef(null);
  const refExemplelucrari = useRef(null);
  const refPortofoliu = useRef(null);
  const refContact = useRef(null);

  const handleClickScroll = (currentRef) => {
    const element = currentRef.current;

    if (element) {
      // 👇 Will scroll smoothly to the top of the next section
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <StyleRoot>
      <div ref={refHome}>
        <Header
          sideNav={sideNav}
          showSideNav={showSideNav}
          handleClickScroll={handleClickScroll}
          refHome={refHome}
          refSpecificatii={refSpecificatii}
          refExemplelucrari={refExemplelucrari}
          refPortofoliu={refPortofoliu}
          refContact={refContact}
        />
        <Content>
          <Specificatii refSpecificatii={refSpecificatii} />
          <ExemplePoduri refExemplelucrari={refExemplelucrari} />
          <Portofoliu refPortofoliu={refPortofoliu} />
        </Content>
        <Footer refContact={refContact} />
      </div>
    </StyleRoot>
  );
}

export default App;
